import { ReactComponent as DeleteSvg } from './delete-icon.svg';

interface Props {
    size?: 'small' | 'large';
    onClick?: () => void;
    className?: string;
}

const DeleteIcon: React.FC<Props> = ({ onClick, className }) => {

    return (
        <>
            <DeleteSvg
                onClick={onClick}
                className={className}
            />
        </>
    )
}


export default DeleteIcon;

