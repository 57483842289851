import styles from './Button.module.css';


interface Props extends React.HTMLAttributes<HTMLButtonElement> {
    children?: React.ReactNode;
    size?: 'large' | 'medium';
    disabled?: boolean;
}

const Button: React.FC<Props> = ({ children, size, disabled, ...buttonProps }) => {

    const fullNameSize = size ? 'size_' + size : 'size_medium'; 

    return (
        <button 
            className={`${styles.button} ${styles[fullNameSize]} ${disabled ? styles.disabled : ''}`}
            {...buttonProps}
            onClick={ disabled ? undefined : buttonProps.onClick }
        >
            {children}
        </button>
    )
}


export default Button;