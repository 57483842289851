import { useState, useEffect } from 'react';
import { UserRoles } from "api/user/userTypes";
import InputField from "components/InputField/InputField";
import Select from "components/Select";
import { useNavigate, useParams } from "react-router";
import styles from './AdminUserForm.module.css';
import Button from 'components/Button/Button';
import { useAppDispatch } from 'hooks/UseAppDispatch';
import { clearCreatedUser, clearUpdatingUser, createUser, getOneUser, updateUser } from 'store/user/userSlice';
import useAppSelector from 'hooks/UseAppSelector';
import LS from 'types/LoadingStatus';
import { setAlert } from 'store/alert/alertSlice';
import Typography from 'components/Typography/Typography';
import { isOrganization, Organization } from 'api/organizations/OrganizationsTypes';
import { loadOrganizations } from 'store/organizations/organizationsSlice';


const roles: RoleSelect[] = [
    { value: UserRoles.super, label: 'Суперадмин' },
    { value: UserRoles.admin, label: 'Aдмин' },
    { value: UserRoles.telegram, label: 'Телеграм' }
]

type RoleSelect = { value: UserRoles, label: string };

const AdminUserForm: React.FC = () => {
    const { id } = useParams();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [role, setRole] = useState<RoleSelect | null>(id ? null : roles[1]);
    const [organization, setOrganization] = useState<Organization | null>(null);

    const { createLS, createdUser, updatingUser, updatingUserLS, updateLS } = useAppSelector(state => state.user);
    const { organizations } = useAppSelector(state => state.organizations);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleSave = () => {
        if (id) {
            dispatch(updateUser({
                _id: id,
                username,
                password,
                role: role?.value || UserRoles.admin,
                organization: organization?._id,
                name
            }))
        } else {
            dispatch(createUser({
                username,
                password,
                role: role?.value || UserRoles.admin,
                organization: organization?._id,
                name
            }))
        }
    }

    useEffect(() => {
        dispatch(loadOrganizations());
    }, [])

    useEffect(() => {
        if (createLS === LS.success) {
            createdUser && navigate(`../${createdUser._id}`)
            dispatch(setAlert({ message: 'Успешно', type: 'success' }))
        }
        return () => { dispatch(clearCreatedUser()) }
    }, [createLS])


    useEffect(() => {
        if (id) {
            dispatch(getOneUser(id))
        }
        return () => { dispatch(clearUpdatingUser()) }
    }, [id])

    useEffect(() => {
        if (updateLS === LS.success) {
            dispatch(setAlert({ message: 'Успешно', type: 'success' }))
        }
    }, [updateLS])

    useEffect(() => {
        if (updatingUserLS === LS.success && updatingUser) {
            setUsername(updatingUser.username);
            setName(updatingUser.name);
            setRole(roles.find(role => role.value === updatingUser.role) || roles[1]);
            setOrganization(isOrganization(updatingUser.organization) ? updatingUser.organization : null);
        }
    }, [updatingUserLS, updatingUser?._id])

    return (
        <div className="page_container">
            <div className={styles.header}>
                <Typography size='large'>{id ? "Изменить" : "Создать"} администратора</Typography>
                <Button size='large' onClick={handleSave}>Сохранить</Button>
            </div>
            <div className={styles.container}>
                <InputField
                    name="username"
                    label="Логин"
                    placeholder="login"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                />
                <InputField
                    label="Пароль"
                    name="password"
                    placeholder="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                />
                <InputField
                    label="ФИО"
                    name="name"
                    placeholder="Введите ФИО"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                />
                <div >
                    <Select
                        label="Роль"
                        isMulti={false}
                        options={roles}
                        value={role}
                        onChange={(value) => setRole(value)}
                    />
                </div>
                {
                    role?.value === UserRoles.admin &&
                    <div>
                        <Select
                            name="organization"
                            isMulti={false}
                            label='Организация'
                            value={organization}
                            options={organizations}
                            getOptionLabel={(opt) => opt.name}
                            getOptionValue={(opt) => opt._id}
                            onChange={(value) => setOrganization(value)}
                        />
                    </div>
                }
            </div>
        </div>
    )
}


export default AdminUserForm;