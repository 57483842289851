import { Route, Routes } from "react-router";
import AdminUsersPage from "./AdminUsersPage";
import AdminUserForm from "./AdminUserForm";


const AdminUsers: React.FC = () => {
    return (
        <Routes>
           <Route index element={<AdminUsersPage/>}/> 
           <Route path="/create" element={<AdminUserForm/>}/>
           <Route path="/:id" element={ <AdminUserForm/> }/>
        </Routes>
    )
}


export default AdminUsers;