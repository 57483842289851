import { ReactComponent as Submit } from './submit-icon.svg';
 
const SubmitIcon: React.FC = () => {
    return (
        <>
            <Submit/>
        </>
    )
}


export default SubmitIcon;