import { ReactComponent as Person } from './person.svg';
 
const PersonIcon: React.FC = () => {
    return (
        <>
            <Person/>
        </>
    )
}


export default PersonIcon;