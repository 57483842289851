
import Profile from 'components/Profile/Profile';
import SideBar from 'components/Sidebar';
import Typography from 'components/Typography/Typography';
import { Outlet } from 'react-router';
import styles from './Header.module.css';

const Header: React.FC = () => {

    return (
        <div>
            <div className={styles.header}>
                <Typography className={styles.logo}>MedApp</Typography>
                <Profile/>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <SideBar/>
                <Outlet/>
            </div>
        </div>
    )
}


export default Header;