import { useEffect, useState } from "react";

/**
 * 
 * @param value for debounce
 * @param delay in millisecond
 * @returns 
 */
export default function useDelay<T = any>(value?: T, delay?: number): T | undefined {
    const [debouncedValue, setDebouncedValue] = useState<T | undefined>(value);
  
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value]);
  
    return debouncedValue;
  }