import Typography from 'components/Typography/Typography';
import styles from './sidebar.module.css';
import { NavLink } from "react-router-dom";
import useAppSelector from 'hooks/UseAppSelector';
import { UserRoles } from 'api/user/userTypes';

const SideBar: React.FC = () => {

    const { user } = useAppSelector(state => state.user);

    const isSuper = () => user?.role === UserRoles.super;
    const isAdmin = () => user?.role || Infinity <= UserRoles.admin;

    return (
        <>
            <div className={styles.container}>

                {
                    isSuper() &&
                    <NavLink
                        to={'admins'}
                        className={({ isActive }) => `${styles.item} ${isActive ? styles.active : ''}`}
                    >
                        <Typography>Администраторы</Typography>
                    </NavLink>
                }

                {
                    isAdmin() &&
                    <NavLink
                        to={'doctors'}
                        className={({ isActive }) => `${styles.item} ${isActive ? styles.active : ''}`}
                    >
                        <Typography>Врачи</Typography>
                    </NavLink>
                }

                {
                    isSuper() &&
                    <NavLink
                        to={'organizations'}
                        className={({ isActive }) => `${styles.item} ${isActive ? styles.active : ''}`}
                    >
                        <Typography>Организации</Typography>
                    </NavLink>
                }


            </div>
        </>
    )
}


export default SideBar;