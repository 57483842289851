import Fetch from "api/Fetch";
import { User, LoginReqParams, LoginRes, CreateUserReqParams, UpdateUserReqParams } from "./userTypes";



export default class UserApi {
    static async login(params: LoginReqParams): Promise<LoginRes> {
        return Fetch.post<LoginRes>('/admin-auth/login', JSON.stringify(params));
    }

    static async getMe(): Promise<User> {
        return Fetch.get<User>('/admin-users/me');
    }

    static async getOne(id: string): Promise<User> {
        return Fetch.get<User>(`/admin-users/${id}`);
    }

    static async createUser(params: CreateUserReqParams): Promise<User> {
        return Fetch.post<User>('/admin-users', JSON.stringify(params));
    }

    static async getUsers(): Promise<User[]> {
        return Fetch.get<User[]>('/admin-users');
    }

    static async updateUser(params: UpdateUserReqParams): Promise<User> {
        return Fetch.put<User>(`/admin-users/${params._id}`, JSON.stringify(params));
    }

    static async removeUser(id: string): Promise<User> {
        return Fetch.delete(`/admin-users/${id}`);
    }
}