import { Organization } from "api/organizations/OrganizationsTypes";

export type User = {
    _id: string;
    username: string;
    role: UserRoles;
    organization?: string  | Organization;
    name: string;
}

export type LoginReqParams = {
    username: string;
    password: string;
}

export type LoginRes = {
    access_token: string;
}

export enum UserRoles {
    super=1,
    admin=2,
    telegram=30,
}

export type CreateUserReqParams = Omit<User, '_id'> & { password: string };

export type UpdateUserReqParams = User & { password: string };