import { isOrganization } from "api/organizations/OrganizationsTypes";
import PersonIcon from "components/icons/Person/PersonIcon";
import Typography from "components/Typography/Typography";
import useAppSelector from "hooks/UseAppSelector";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { clearLoginData, clearUser } from "store/user/userSlice";
import styles from './Profile.module.css';

const Profile: React.FC = () => {
    const { user } = useAppSelector(state => state.user);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        navigate('/login');
        dispatch(clearUser());
        dispatch(clearLoginData());
    }
    return (
        <div className={styles.container} onClick={handleLogout}>
            <Typography className={styles.organization}>{isOrganization(user?.organization) && user?.organization.name}</Typography>
           <Typography weight="light" size="small">{user?.name || user?.username}</Typography> 
           <PersonIcon/>
        </div>
    )
}


export default Profile;