import { isOrganization } from "api/organizations/OrganizationsTypes";
import Button from "components/Button/Button";
import DeleteIcon from "components/icons/DeleteIcon";
import EditIcon from "components/icons/EditIcon";
import Typography from "components/Typography/Typography";
import { useAppDispatch } from "hooks/UseAppDispatch";
import useAppSelector from "hooks/UseAppSelector";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setAlert } from "store/alert/alertSlice";
import { clearRemoveUser, getUsers, removeUser } from "store/user/userSlice";
import LS from "types/LoadingStatus";
import styles from './AdminUsersPage.module.css';


const AdminUsersPage: React.FC = () => {

    const { users, removeLS } = useAppSelector(state => state.user);

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const handleAddAdmin = () => {
        navigate('create');
    }

    useEffect(() => {
        dispatch(getUsers());
    }, []);

    useEffect(() => {
        if(removeLS === LS.success) {
            dispatch(setAlert({message: 'Успешно удалено', type: 'success'}));
        }
        return () => { dispatch(clearRemoveUser()) }
    }, [removeLS])

    const handleEdit = (id: string) => {
        navigate(id);
    }

    const handleRemove = (id: string) => {
        if(window.confirm('Вы уверены?')) {
            dispatch(removeUser(id));
        }
    }

    const roles = ['', 'Суперадмин', 'Админ'];
    return (
        <>
            <div className="page_container">
                <div className={styles.add_admin}>
                    <Button size="large" onClick={handleAddAdmin}>Добавить администратора</Button>
                </div>

                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th><Typography>Логин</Typography></th>
                            <th><Typography>ФИО</Typography></th>
                            <th><Typography>Роль</Typography></th>
                            <th><Typography>Организация</Typography></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users.map(admin => (
                                <tr key={admin._id}>
                                    <td><Typography>{admin.username}</Typography></td>
                                    <td><Typography>{admin.name}</Typography></td>
                                    <td><Typography>{admin.role === 30 ? 'Телеграм' : roles[admin.role]}</Typography></td>
                                    <td><Typography>
                                        {isOrganization(admin.organization) && admin.organization.name}
                                    </Typography></td>
                                    <td>
                                        <EditIcon 
                                        className={styles.icon}
                                        onClick={() => handleEdit(admin._id)}
                                    />
                                    </td>
                                    <td>
                                        <DeleteIcon 
                                            className={styles.icon}
                                            onClick={() => handleRemove(admin._id)}
                                        />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}


export default AdminUsersPage;