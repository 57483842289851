import { useEffect } from 'react';
import { useAppDispatch } from "hooks/UseAppDispatch";
import useAppSelector from "hooks/UseAppSelector";
import { Navigate, useLocation } from "react-router";
import { getUser } from 'store/user/userSlice';
import LS from 'types/LoadingStatus';
import Loader from 'components/Loader/Loader';

interface Props {
    children?: React.ReactNode;
}

const RequireAuth: React.FC<Props> = ({ children }) => {

    const location = useLocation();

    const dispatch = useAppDispatch();
    const { user, userLS } = useAppSelector(state => state.user);

    useEffect(() => {
        dispatch(getUser());
    }, []);

    if (user) {
        return <>{children}</>;
    }

    if (userLS === LS.error) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return (
        <div className="main_loader">
            <div>
                <Loader />
            </div>
        </div>
    )
}



export default RequireAuth;