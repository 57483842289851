import { isOrganization, Organization } from "api/organizations/OrganizationsTypes";
import Button from "components/Button/Button";
import Typography from "components/Typography/Typography";
import { useAppDispatch } from "hooks/UseAppDispatch";
import useAppSelector from "hooks/UseAppSelector";
import { useEffect, useState } from "react";
import { changeAccessDoctor, clearSyncDoctors, clearUpdateDoctor, loadDoctors, syncDoctors, updateDoctor } from "store/doctor/doctorSlice";
import LS from "types/LoadingStatus";
import styles from './Doctors.module.css';
import Select from "components/Select";
import { loadOrganizations } from "store/organizations/organizationsSlice";
import { User, UserRoles } from "api/user/userTypes";
import { setAlert } from "store/alert/alertSlice";
import InputField from "components/InputField/InputField";
import useDelay from "hooks/useDelay";
import Loader from "components/Loader/Loader";
import { getUser, getUsers } from "store/user/userSlice";
import { MultiValue } from "react-select";


const Doctors: React.FC = () => {

    const [organization, setOrganization] = useState<Organization | null>(null);
    const [admin, setAdmin] = useState<User | null>(null);
    const [phone, setPhone] = useState<string>('');
    const [name, setName] = useState<string>('');

    const { doctors, syncDoctorsLS, doctorsLS, updateLS } = useAppSelector(state => state.doctor);
    const { organizations, organizationsLS } = useAppSelector(state => state.organizations);
    const { user, users } = useAppSelector(state => state.user);

    const dispatch = useAppDispatch();

    const delayedPhone = useDelay(phone, 800);
    const delayedName = useDelay(name, 800);

    useEffect(() => {
        if(user?.role === UserRoles.admin) {
            setAdmin(user);
        }
    }, [user?._id])

    useEffect(() => {
        dispatch(loadDoctors({ 
            organization: organization?._id, 
            admin: admin?._id,
            phone: delayedPhone, 
            name: delayedName,
            limit: user?.role === UserRoles.super ? 400 : undefined
        }));
    }, [organization?._id, delayedPhone, delayedName, admin?._id]);

    useEffect(() => {
        (user?.role === UserRoles.super && organizationsLS !== LS.success) && dispatch(loadOrganizations());
    }, [organizationsLS, user?.role])

    useEffect(() => {
        dispatch(getUsers());
    }, [])

    useEffect(() => {
        if (syncDoctorsLS === LS.success) {
            dispatch(setAlert({ message: 'Успешная синхронизация', type: 'success' }));
            setPhone('');
            setOrganization(null);
            dispatch(loadDoctors({limit: user?.role === UserRoles.super ? 400 : undefined}));
        } return () => {
            dispatch(clearSyncDoctors());
        }
    }, [syncDoctorsLS])

    useEffect(() => {
        if(updateLS === LS.success) {
            dispatch(setAlert({message: 'Успешно', type: 'success'}));
            dispatch(getUser());
        }
        else if(updateLS === LS.error) dispatch(setAlert({message: 'Ошибка. Проверьте количество доступных пользователей', type: 'error'}));

        return () => { dispatch(clearUpdateDoctor()) }
    }, [updateLS])

    const handleChangeAccess = (id: string, isAccess: boolean) => {
        dispatch(changeAccessDoctor({ _id: id, isAccess }))
    }

    const handleChangeAdmins = (id: string, admins: MultiValue<User>) => {
        dispatch(updateDoctor({ _id: id, admins: admins.map(admin => admin._id) }))
    }


    const handleSync = () => {
        syncDoctorsLS !== LS.pending && dispatch(syncDoctors());
    }

    const getAccessCount = () => {
        return isOrganization(user?.organization) ? user?.organization.usersCount || 0 : 0
    }

    const getDoctorsCountWithAccess = () => {
        let count = isOrganization(user?.organization) ? user?.organization.currentUsersCount || 0 : 0;
        return getAccessCount() - count;
    }

    return (
        <div className="page_container">
            <div className={styles.header}>
                <Typography size='large'>Врачи</Typography>
                {
                    user?.role === UserRoles.admin && user.organization &&
                    <Typography>Доступно {getDoctorsCountWithAccess()} из {getAccessCount()}</Typography>
                }
                <Button
                    size='large'
                    onClick={handleSync}
                >
                    Синхронизировать
                </Button>
            </div>

            <div className={styles.subheader}>
                {
                    user?.role === UserRoles.super &&
                    <div style={{ width: '250px' }}>
                        <Select
                            isClearable={true}
                            isMulti={false}
                            name="organization"
                            value={organization}
                            options={organizations}
                            getOptionLabel={(opt) => opt.name}
                            getOptionValue={(opt) => opt._id}
                            onChange={(value) => setOrganization(value)}
                            placeholder="Выберите огранизацию"
                        />
                    </div>
                }

                <Select
                    isMulti={false}
                    isClearable={true}
                    name="admins"
                    value={admin}
                    options={users.filter(usr => usr.role === UserRoles.admin)}
                    getOptionLabel={(opt) => opt.name}
                    getOptionValue={(opt) => opt._id}
                    onChange={(value) => setAdmin(value)}
                    placeholder="Выберите администратора"
                    noOptionsMessage={() => <Typography>Нет администраторов</Typography>}
                />

                <InputField
                    name="phone"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    placeholder="Введите телефон"
                />
                <InputField
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    placeholder="Введите ФИО"
                />
            </div>
            {
                doctorsLS === LS.pending || syncDoctorsLS === LS.pending ?
                    <div className="main_loader" style={{ height: '50vh' }}>
                        <div>
                            <Loader />
                        </div>
                    </div> :
                    <>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th><Typography>Телефон</Typography></th>
                                    <th><Typography>ФИО</Typography></th>
                                    { user?.role === UserRoles.super && <th><Typography>Огранизация</Typography></th> }
                                    <th style={{width : '50%' }}></th>
                                    <th align="center"><Typography>Есть доступ</Typography></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    doctors.map(doctor => (
                                        <tr key={doctor._id}>
                                            <td><Typography>{doctor.phone}</Typography></td>
                                            <td><Typography>{doctor.fullName}</Typography></td>
                                            {user?.role === UserRoles.super && <td><Typography>{doctor.organization?.name}</Typography></td>}
                                            <td>
                                                <Select
                                                    isMulti={true}
                                                    isClearable={true}
                                                    name="admins"
                                                    value={doctor.admins}
                                                    options={users.filter(usr => isOrganization(usr.organization) ? usr.organization._id === doctor.organization._id : usr.organization === doctor.organization._id)}
                                                    getOptionLabel={(opt) => opt.name}
                                                    getOptionValue={(opt) => opt._id}
                                                    onChange={(value) => handleChangeAdmins(doctor._id, value)}
                                                    placeholder="Выберите администраторов"
                                                    noOptionsMessage={() => <Typography>Нет администраторов</Typography>}
                                                />
                                            </td>
                                            <td align="center">
                                                <input 
                                                    type="checkbox"
                                                    checked={doctor.isAccess}
                                                    onChange={(e) => handleChangeAccess(doctor._id, e.target.checked)}
                                                    disabled={updateLS === LS.pending}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        {(user?.role === UserRoles.super && doctors.length > 399) && <Typography>Просмотр ограничен 400 записями. Для более детального просмотра начните поиск или выберите организацию</Typography>}
                    </>
            }

        </div>
    )
}


export default Doctors;