import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserApi from 'api/user/UserApi';
import { CreateUserReqParams, LoginReqParams, LoginRes, UpdateUserReqParams, User } from 'api/user/userTypes';
import LS from 'types/LoadingStatus';

export const login = createAsyncThunk(
    'user/login',
    async (params: LoginReqParams) => {
        return UserApi.login(params);
    }
)

export const getUser = createAsyncThunk(
    'user/me',
    async () => {
        return UserApi.getMe();
    }
)

export const getOneUser = createAsyncThunk(
    'user/getOne',
    async (id: string) => {
        return UserApi.getOne(id);
    }
)

export const getUsers = createAsyncThunk(
    'user/get',
    async () => {
        return UserApi.getUsers();
    }
)

export const createUser = createAsyncThunk(
    'user/create',
    async (params: CreateUserReqParams) => {
        return UserApi.createUser(params);
    }
)

export const updateUser = createAsyncThunk(
    'user/update',
    async (params: UpdateUserReqParams) => {
        return UserApi.updateUser(params);
    }
)

export const removeUser = createAsyncThunk(
    'user/remove',
    async (id: string) => {
        return UserApi.removeUser(id);
    }
)



interface UserSliceState {
    user?: User;
    loginData?: LoginRes;
    users: User[];
    createdUser?: User;
    updatingUser?: User;

    userLS: LS;
    usersLS: LS;
    loginLS: LS;
    createLS: LS;
    updateLS: LS;
    updatingUserLS: LS;
    removeLS: LS;
}


const initialState: UserSliceState = {
    user: undefined,
    loginData: undefined,
    users: [],
    createdUser: undefined,
    updatingUser: undefined,

    userLS: LS.idle,
    usersLS: LS.idle,
    loginLS: LS.idle,
    createLS: LS.idle,
    updatingUserLS: LS.idle,
    updateLS: LS.idle,
    removeLS: LS.idle
};


const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearCreatedUser(state) {
            state.createLS = LS.idle;
            state.createdUser = undefined;
        },
        clearUpdatingUser(state) {
            state.updateLS = LS.idle;
            state.updatingUser = undefined;
            state.updatingUserLS = LS.idle;
        },

        clearRemoveUser(state) {
            state.removeLS = LS.idle;
        },

        clearUser(state) {
            state.user = initialState.user;
            state.userLS = LS.idle;
        },

        clearLoginData(state) {
            state.loginData = { access_token: '-1' };
            state.loginLS = LS.idle;
        }
    },
    extraReducers: (builder) => {

        // LOGIN
        builder.addCase(login.pending, (state, action) => {
            state.loginLS = LS.pending;
        })
        builder.addCase(login.fulfilled, (state, action) => {
            state.loginLS = LS.success;
            state.loginData = action.payload;
        })
        builder.addCase(login.rejected, (state, action) => {
            state.loginLS = LS.error;
        })

        // GET Me
        builder.addCase(getUser.pending, (state, action) => {
            state.userLS = LS.pending;
        })
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.userLS = LS.success;
            state.user = action.payload;
        })
        builder.addCase(getUser.rejected, (state, action) => {
            state.userLS = LS.error;
        })

        // GET ONE
        builder.addCase(getOneUser.pending, (state, action) => {
            state.updatingUserLS = LS.pending;
        })
        builder.addCase(getOneUser.fulfilled, (state, action) => {
            state.updatingUserLS = LS.success;
            state.updatingUser = action.payload;
        })
        builder.addCase(getOneUser.rejected, (state, action) => {
            state.updatingUserLS = LS.error;
        })

        // GET USERS
        builder.addCase(getUsers.pending, (state, action) => {
            state.usersLS = LS.pending;
        })
        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.usersLS = LS.success;
            state.users = action.payload;
        })
        builder.addCase(getUsers.rejected, (state, action) => {
            state.usersLS = LS.error;
        })

        // CREATE USER
        builder.addCase(createUser.pending, (state, action) => {
            state.createLS = LS.pending;
        })
        builder.addCase(createUser.fulfilled, (state, action) => {
            state.createLS = LS.success;
            state.createdUser = action.payload;
        })
        builder.addCase(createUser.rejected, (state, action) => {
            state.createLS = LS.error;
        })

        // UPDATE USER
        builder.addCase(updateUser.pending, (state, action) => {
            state.updateLS = LS.pending;
        })
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.updateLS = LS.success;
            state.updatingUser = action.payload;
        })
        builder.addCase(updateUser.rejected, (state, action) => {
            state.updateLS = LS.error;
        })

        // REMOVE USER
        builder.addCase(removeUser.pending, (state, action) => {
            state.removeLS = LS.pending;
        })
        builder.addCase(removeUser.fulfilled, (state, action) => {
            state.removeLS = LS.success;
            const {_id } = action.payload;
            state.users = state.users.filter(user => user._id !== _id);
        })
        builder.addCase(removeUser.rejected, (state, action) => {
            state.removeLS = LS.error;
        })
    }
});


export const { clearCreatedUser, clearUpdatingUser, clearRemoveUser, clearUser, clearLoginData } = userSlice.actions;
export default userSlice.reducer;