export type Organization = {
    _id: string,
    name: string,
    externalId: string;
    usersCount?: number;
    currentUsersCount?: number;
}

export type UpdateOrganizationReq = {
    id: string;
    usersCount: number;
}

export const isOrganization = (organization: Organization | any): organization is Organization => {
    return (
        organization && 
        organization.name && typeof organization.name === 'string' 
        && organization._id && typeof organization.name === 'string' 
        && organization.externalId && typeof organization.externalId === 'string'
    )
}