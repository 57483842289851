import { useState, useEffect } from 'react';
import { Organization } from 'api/organizations/OrganizationsTypes';
import InputField from 'components/InputField/InputField';
import Typography from 'components/Typography/Typography';
import styles from './Organization.module.css';
import Button from 'components/Button/Button';
import SubmitIcon from 'components/icons/SubmitIcon';
import { useAppDispatch } from 'hooks/UseAppDispatch';
import { clearOrganizationUpdate, updateOrganization } from 'store/organizations/organizationsSlice';
import { setAlert } from 'store/alert/alertSlice';
import useAppSelector from 'hooks/UseAppSelector';
import LS from 'types/LoadingStatus';

type Props = {
    organization: Organization;
}

const OrganizationRow: React.FC<Props> = ({ organization }) => {

    const defaultCount = `${organization.usersCount || '0'}`;

    const [usersCount, setUsersCount] = useState(defaultCount);
    
    const { updateLS } = useAppSelector(state => state.organizations);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if(updateLS === LS.success) {
            dispatch(setAlert({ message: 'Успешно', type: 'success' }));
        } else if(updateLS === LS.error) {
            dispatch(setAlert({ message: 'Ошибка. Проверьте тип значения', type: 'error' }));
        }
        return () => { dispatch(clearOrganizationUpdate()) }
    }, [ updateLS ])

    const changedAndValid = () => {
        return defaultCount !== usersCount && usersCount;
    }

    const handleSubmit = () => {
        const validUserCount = parseInt(usersCount);

        if(!isNaN(validUserCount) && validUserCount >= 0) {
            dispatch(updateOrganization({ id: organization._id, usersCount: validUserCount }));
        } else {
            dispatch(setAlert({ message: 'Неправильный тип значения. Введите число', type: 'error' }))
        }
    }

    return (
        <tr>
            <td><Typography>{organization.name}</Typography></td>
            <td><Typography>{organization.currentUsersCount || 0}</Typography></td>
            <td>
                <div style={{ display: 'flex', alignItems: 'flex-end', gap: '30px' }}>
                    <InputField
                        name='usersCount'
                        type="number"
                        min={0}
                        value={usersCount}
                        style={{ width: '80px' }}
                        onChange={(e) => setUsersCount(e.target.value)}
                    />
                    {
                        changedAndValid() &&
                        <Button 
                            style={{ padding: '5px', height: 'auto' }}
                            onClick={handleSubmit}
                        >
                            <SubmitIcon />
                        </Button>
                    }
                </div>
            </td>
        </tr>
    )
}


export default OrganizationRow;