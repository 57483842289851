
export type QueryParams =  Record<string, any >;

type HttpErrorHandler = (status: number, message?: string) => void;

type Errors = Record<string, string>


export default class Fetch {

    private static baseUrl?: string = '';
    private static baseConfig?: RequestInit = {};
    private static httpErrorHandler?: HttpErrorHandler; 

    private constructor() {}

    public static init(baseUrl?: string, config?: RequestInit, httpErrorHandler?: HttpErrorHandler) {
        this.baseUrl = baseUrl;
        this.baseConfig = config || {};
        this.httpErrorHandler = httpErrorHandler;
    }

    public static async get<Response>(url: string, params?: QueryParams, config?: RequestInit, errors?: Errors): Promise<Response> {
        const fullConfig: RequestInit = {}

        Object.assign(fullConfig, this.baseConfig, config, { method: 'GET' });

        const fullUrl = this.baseUrl + url + '?' + this.queryParamsToUrlParams(params);

        const response = await fetch(fullUrl, fullConfig);
        this.handleFetchErrors(response, url);

        const responseData = await response.json();

        return responseData;
    }


    public static async post<T>(url: string, body?: BodyInit, config?: RequestInit, errors?: Errors): Promise<T> {

        const fullConfig: RequestInit = {}

        Object.assign(fullConfig, this.baseConfig, config, { method: 'POST', body: body || null});

        const response = await fetch(this.baseUrl + url, fullConfig);

        await this.handleFetchErrors(response, url);

        const responseData = await response.json();

        return responseData;
    }

    public static async put<T>(url: string, body: BodyInit, config?: RequestInit, errors?: Errors): Promise<T> {

        const fullConfig: RequestInit = {}

        Object.assign(fullConfig, this.baseConfig, config, { method: 'PUT', body: body });

        const response = await fetch(this.baseUrl + url, fullConfig);
        await this.handleFetchErrors(response, url);

        const responseData = await response.json();

        return responseData;
    }

    public static async delete<T>(url: string, body?: BodyInit, config?: RequestInit, errors?: Errors): Promise<T> {

        const fullConfig: RequestInit = {}

        Object.assign(fullConfig, this.baseConfig, config, { method: 'DELETE', body: body });

        const response = await fetch(this.baseUrl + url, fullConfig);
        await this.handleFetchErrors(response, url);

        const responseData = await response.json();

        return responseData;
    }

    public static async download(url: string, fileName: string, params?: QueryParams, config?: RequestInit, errors?: Errors) {
        const fullConfig: RequestInit = {}

        Object.assign(fullConfig, this.baseConfig, config, { method: 'GET' });

        const fullUrl = this.baseUrl + url + '?' + this.queryParamsToUrlParams(params);

        const response = await fetch(fullUrl, fullConfig);
        this.handleFetchErrors(response, url);

        const blob = await response.blob();
        const fileUrl = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = fileUrl;
        a.download = fileName || 'filename';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();    
        a.remove();
        window.URL.revokeObjectURL(fileUrl);
    }

    private static handleFetchErrors(response: Response, url: string, errors?: Errors) {
        const checkedErrors = errors || {}; 
        if(!response.ok) {
            const error = checkedErrors[response.status];
            this.httpErrorHandler && this.httpErrorHandler(response.status, error);
            throw error;
        }
    }

    static queryParamsToUrlParams = (params?: QueryParams): string => {
        let urlParams = '';
        
        for(let paramKey in params) {
            if(params[paramKey]) {
                urlParams = urlParams + `${paramKey}=${params[paramKey]}&`;
            }
        }

        return urlParams;
    }
}


