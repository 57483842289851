import Fetch from "api/Fetch";
import { changeAccessDoctorReq, Doctor, GetDoctorsResponse, updateDoctorReq } from "./DoctorTypes";



export default class DoctorApi {

    static async getDoctors(params: GetDoctorsResponse): Promise<Doctor[]> {
        return Fetch.get<Doctor[]>('/users/externalUsers', params);
    }

    static async syncDoctors(): Promise<void> {
        return Fetch.post('/users/externalUsers/sync');
    }

    static async updateDoctor(params: updateDoctorReq): Promise<Doctor> {
        return Fetch.put(`/users/externalUsers/${params._id}`, JSON.stringify(params));
    }

    static async changeAccess(params: changeAccessDoctorReq): Promise<Doctor> {
        return Fetch.post(`/users/externalUsers/${params._id}/access`, JSON.stringify(params));
    }
}