import Button from 'components/Button/Button';
import Typography from 'components/Typography/Typography';
import { useAppDispatch } from 'hooks/UseAppDispatch';
import useAppSelector from 'hooks/UseAppSelector';
import { useEffect } from 'react';
import { setAlert } from 'store/alert/alertSlice';
import { clearSync, loadOrganizations, syncOrganizations } from 'store/organizations/organizationsSlice';
import LS from 'types/LoadingStatus';
import OrganizationRow from './OrganizationRow';
import styles from './Organizations.module.css';

const Organizations: React.FC = () => {

    const { organizations, syncLS } = useAppSelector(state => state.organizations);

    const dispatch = useAppDispatch();

    const handleSync = () => {
        syncLS !== LS.pending && dispatch(syncOrganizations());
    }

    useEffect(() => {
        dispatch(loadOrganizations());
    }, [])

    useEffect(() => {
        if(syncLS === LS.success) {
            dispatch(setAlert({ message: 'Синхронизация прошла успешно', type: 'success' }))
            dispatch(loadOrganizations());
        } else if (syncLS === LS.error) {
            dispatch(setAlert({ message: 'Произошла ошибка при синхронизации', type: 'error' }))
        }
        return () => { dispatch(clearSync()) }
    }, [syncLS])

    return (
        <div className='page_container'>
            <div className={styles.header}>
                <Typography size='large'>Организации</Typography>
                <Button 
                    size='large'
                    onClick={handleSync}
                >
                    Синхронизировать
                </Button>
            </div>
            <table className={styles.table}>
                    <thead>
                        <tr>
                            <th><Typography>Название</Typography></th>
                            <th><Typography>Текущее кол-во юзеров</Typography></th>
                            <th><Typography>Разрешенное кол-во юзеров</Typography></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            organizations.map(org => (
                                <OrganizationRow organization={org} key={org._id}/>
                            ))
                        }
                    </tbody>
                </table>
        </div>
    )
}


export default Organizations;