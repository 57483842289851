import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import alertReducer from './alert/alertSlice';
import organizationsReducer from './organizations/organizationsSlice';
import doctorReducer from './doctor/doctorSlice';



export const store = configureStore({
    reducer: {
        user: userReducer,
        alert: alertReducer,
        organizations: organizationsReducer,
        doctor: doctorReducer,
    },
});


export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;