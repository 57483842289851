import styles from './Typography.module.css';

interface Props {
    comp?: 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    size?: 'medium' | 'large' | 'small' | 'ssmall';
    weight?: 'bold' | 'light' | 'medium';
    children?: React.ReactNode;
    className?: string;
}

const Typography: React.FC<Props> = ({ comp, children, size, weight, className }) => {

    const Component = comp || 'p';

    const fullNameSize = size ? `size_${size}` : 'size_medium';

    const fullNameWeight = weight ? `weight_${weight}` : 'weight_medium';

    return (
        <Component 
            className={`${styles.typography} ${styles[fullNameSize]} ${styles[fullNameWeight]} ${className || ''}`}
            
        >
            {children}
        </Component>
    )
}


export default Typography;