import Typography from "components/Typography/Typography";
import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "store/alert/alertSlice";
import { RootState } from "store/store";
import styles from './AsyncAlert.module.css';


const AsyncAlert: React.FC = () => {

    const dispatch = useDispatch();

    const { current: alert } = useSelector((state: RootState) => state.alert);

    const handleOnClose = () => {
        dispatch(setAlert(undefined));
    }

    useEffect(() => {
        let timeout: any = undefined;
        if(alert) {
            timeout = setTimeout(handleOnClose, 2000);
        } 
        return () => clearTimeout(timeout);
    }, [alert])

    const open = Boolean(alert);
    return (
        <div 
            className={`${styles.snackbar} ${open ? styles.show : ''}`}
        >
            {
                alert &&
                <Typography>{alert.message}</Typography>
            }
        </div>
    )
}


export default AsyncAlert;
