import { useState, useEffect } from 'react';
import styles from './Login.module.css';
import InputField from "components/InputField/InputField";
import Button from 'components/Button/Button';
import { useAppDispatch } from 'hooks/UseAppDispatch';
import { login } from 'store/user/userSlice';
import useAppSelector from 'hooks/UseAppSelector';
import LS from 'types/LoadingStatus';
import { useNavigate } from 'react-router';
import { setAlert } from 'store/alert/alertSlice';


const Login: React.FC = () => {

    const [ username, setUsername ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');

    const dispatch = useAppDispatch();

    const { loginLS } = useAppSelector(state => state.user);

    const navigate = useNavigate();

    const handleSubmit = () => {
        (username && password) && dispatch(login({ username: username, password }));
    }

    const handleEnterDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if(event.key === 'Enter') {
            handleSubmit();
        }
    }

    useEffect(() => {
        if(loginLS === LS.success) {
            navigate('/');
        } else if(loginLS === LS.error) {
            dispatch(setAlert({message: 'Неправильный логин или пароль', type: 'error'}))
        }
    }, [ loginLS ])

    return (
        <div 
            className={styles.container}
            onKeyDown={handleEnterDown}
            tabIndex={-1}
        >
            <div className={styles.container}>
                <InputField 
                    name='username' 
                    label="Логин"
                    placeholder='Введите логин'
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                />
                <InputField 
                    name='password' 
                    label="Пароль"
                    placeholder='Введите пароль'
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <div className={styles.button_container}>
                    <Button
                        size='large'
                        onClick={handleSubmit}
                        disabled={!password || !username}
                    >
                        Войти
                    </Button>
                </div>
            </div>
        </div>
    )
}


export default Login;