import Fetch from "api/Fetch";
import { Organization, UpdateOrganizationReq } from "./OrganizationsTypes";



export default class OrganizationsApi {

    static async getOrganizations(): Promise<Organization[]> {
        return Fetch.get<Organization[]>('/organizations');
    }

    static async syncOrganizations(): Promise<void> {
        return Fetch.post('/organizations/sync');
    }

    static async update(params: UpdateOrganizationReq): Promise<Organization> {
        return Fetch.put<Organization>(`/organizations/${params.id}`, JSON.stringify(params));
    }
}