import { ReactComponent as EditSvg } from './edit-icon.svg';

interface Props {
    onClick?: () => void,
    className?: string;
}

const EditIcon: React.FC<Props> = ({ onClick, className }) => {

    return (
        <>
            <EditSvg
                onClick={onClick}
                className={className}
            />
        </>
    )
}


export default EditIcon;

