import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import DoctorApi from 'api/doctors/DoctorApi';
import { changeAccessDoctorReq, Doctor, GetDoctorsResponse, updateDoctorReq } from 'api/doctors/DoctorTypes';
import LS from 'types/LoadingStatus';

export const syncDoctors = createAsyncThunk(
    'doctor/sync',
    async () => {
        return DoctorApi.syncDoctors();
    }
)

export const loadDoctors = createAsyncThunk(
    'doctor/load',
    async (params: GetDoctorsResponse) => {
        return DoctorApi.getDoctors(params);
    }
)

export const updateDoctor = createAsyncThunk(
    'doctor/update',
    async (params: updateDoctorReq) => {
        return DoctorApi.updateDoctor(params);
    }
)

export const changeAccessDoctor = createAsyncThunk(
    'doctor/changeAccess',
    async (params: changeAccessDoctorReq) => {
        return DoctorApi.changeAccess(params);
    }
)

interface DoctorSliceState {
    doctors: Doctor[],

    syncDoctorsLS: LS;
    doctorsLS: LS;
    updateLS: LS;
}


const initialState: DoctorSliceState = {
    doctors: [],

    syncDoctorsLS: LS.idle,
    doctorsLS: LS.idle,
    updateLS: LS.idle,
};


const doctorSlise = createSlice({
    name: 'doctor',
    initialState,
    reducers: {
        clearSyncDoctors(state) {
            state.syncDoctorsLS = LS.idle;
        },
        clearUpdateDoctor(state) {
            state.updateLS = LS.idle;
        }
    },
    extraReducers: (builder) => {
        // LOAD DOCTORS
        builder.addCase(loadDoctors.pending, (state, action) => {
            state.doctorsLS = LS.pending;
        })
        builder.addCase(loadDoctors.fulfilled, (state, action) => {
            state.doctorsLS = LS.success;
            state.doctors = action.payload;
        })
        builder.addCase(loadDoctors.rejected, (state, action) => {
            state.doctorsLS = LS.error;
        })

        // SYNC
        builder.addCase(syncDoctors.pending, (state, action) => {
            state.syncDoctorsLS = LS.pending;
        })
        builder.addCase(syncDoctors.fulfilled, (state, action) => {
            state.syncDoctorsLS = LS.success;
        })
        builder.addCase(syncDoctors.rejected, (state, action) => {
            state.syncDoctorsLS = LS.error;
        })

        // UPDATE DOCTOR
        builder.addCase(updateDoctor.pending, (state, action) => {
            state.updateLS = LS.pending;
        })
        builder.addCase(updateDoctor.fulfilled, (state, action) => {
            state.updateLS = LS.success;
            const updatedDoctor = action.payload;
            state.doctors = state.doctors.map(doctor => {
                if(doctor._id === updatedDoctor._id) return updatedDoctor;
                return doctor;
            })
        })
        builder.addCase(updateDoctor.rejected, (state, action) => {
            state.updateLS = LS.error;
        })

        // CHANGE ACCESS DOCTOR
        builder.addCase(changeAccessDoctor.pending, (state, action) => {
            state.updateLS = LS.pending;
        })
        builder.addCase(changeAccessDoctor.fulfilled, (state, action) => {
            state.updateLS = LS.success;
            const updatedDoctor = action.payload;
            state.doctors = state.doctors.map(doctor => {
                if(doctor._id === updatedDoctor._id) return updatedDoctor;
                return doctor;
            })
        })
        builder.addCase(changeAccessDoctor.rejected, (state, action) => {
            state.updateLS = LS.error;
        })
    }
});


export const { clearSyncDoctors, clearUpdateDoctor } = doctorSlise.actions;
export default doctorSlise.reducer;