import { useEffect, useLayoutEffect } from 'react';
import Fetch from "api/Fetch";
import Header from "components/Header/Header";
import Loader from "components/Loader/Loader";
import RequireAuth from "components/RequireAuth/RequireAuth";
import useDelay from "hooks/useDelay";
import Login from "pages/Login/Login";
import { Routes, Route, Navigate } from "react-router";
import useAppSelector from 'hooks/UseAppSelector';
import { useLocalStorage } from 'hooks/useLocalStorage';
import AdminUsers from 'pages/AdminUsers';
import Organizations from 'pages/Organizations';
import { UserRoles } from 'api/user/userTypes';
import Doctors from 'pages/Doctors';
import AsyncAlert from 'components/AsyncAlert';

const API_URL = process.env.REACT_APP_API_URL;

if(!API_URL) console.log('API_URL is not exists. Using localhost')

const App: React.FC = () => {

    const [ token, setToken ] = useLocalStorage('token', '');

    const { loginData, user } = useAppSelector(state => state.user);

    useLayoutEffect(() => {
        Fetch.init(
            API_URL || 'http://localhost:3000',
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            }
        )
    }, [token])

    useEffect(() => {
        if(loginData?.access_token) {
            setToken(loginData.access_token);
        }
    }, [ loginData?.access_token ])

    const isSuper = () => user?.role === UserRoles.super;
    const isAdmin = () => user?.role || Infinity <= UserRoles.admin;
    
    return (
        <>
            <AsyncAlert/>
            <Routes>
                <Route path='/*' element={
                    <RequireAuth>
                        <Routes>
                            <Route element={<Header/>}>
                                <Route path = 'doctors/*' element={<Doctors/>}/>
                                { isSuper() && <Route path="admins/*" element={<AdminUsers/>} /> }
                                { isSuper() && <Route path='organizations' element={<Organizations/>} /> }
                                <Route path="*" element={<Navigate replace to={isSuper() ? 'admins' : 'doctors'}/>}/>
                            </Route>
                        </Routes>
                    </RequireAuth>
                } />
                <Route path="/login" element={<Login />} />
            </Routes>
        </>
    )
}


export default App;