import styles from './Loader.module.css';

const Loader: React.FC = () => {
    return (
        <>
            <div className={styles.loader}></div>
        </>
    )
}


export default Loader;