import Typography from 'components/Typography/Typography';
import { InputHTMLAttributes } from 'react';
import styles from './InputField.module.css';


interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
}

const InputField: React.FC<Props> = ({ name, label, ...inputProps }) => {
    return (
        <div>
           <label htmlFor={name}>
                <Typography
                   size='small'
                   weight='light'
                >
                    {label}
                </Typography>
            </label> 
           <input 
                className={styles.input}
                id={name}
                {...inputProps}
            />
        </div>
    )
}


export default InputField;