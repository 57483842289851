import Typography from 'components/Typography/Typography';
import ReactSelect, { Props as SelectProps } from 'react-select';

export interface SelectFieldProps<Option, IsMulti extends boolean> extends SelectProps<Option, IsMulti> {
    error?: boolean;
    helperText?: string;
    label?: string;
}

function Select<Option, IsMulti extends boolean>({
    id,
    label,
    value,
    onChange,
    options,
    ...otherProps
}: SelectFieldProps<Option, IsMulti>): React.ReactElement {
    return (
        <div>
            {
                label &&
                <label htmlFor={id}>
                    <Typography
                        size='small'
                        weight='light'
                    >
                        {label}
                    </Typography>
                </label>
            }
            <ReactSelect
                inputId={id}
                styles={{
                    control: (styles) => ({
                        ...styles,
                        borderColor: '#00b44e87',
                        boxShadow: 'none',
                        minWidth: '257px',
                        marginTop: '8px',
                        ':hover': {
                            borderColor: '#00b44e87',
                        },
                        fontFamily: 'Roboto'
                    }),
                    option: (styles, { isSelected }) => ({
                        ...styles,
                        fontFamily: 'Roboto',
                        backgroundColor: isSelected ? '#00b44e87' : styles.backgroundColor
                    })
                }}
                value={value}
                onChange={onChange}
                options={options}
                {...otherProps}
            />
        </div>
    );
}


export default Select;