import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import OrganizationsApi from 'api/organizations/OrganizationsApi';
import { Organization, UpdateOrganizationReq } from 'api/organizations/OrganizationsTypes';
import LS from 'types/LoadingStatus';


export const loadOrganizations = createAsyncThunk(
    'organizations/load',
    async() => {
        return OrganizationsApi.getOrganizations();
    }
)

export const syncOrganizations = createAsyncThunk(
    'organizations/sync',
    async() => {
        return OrganizationsApi.syncOrganizations();
    }
)

export const updateOrganization = createAsyncThunk(
    'organizations/update',
    async(params: UpdateOrganizationReq) => {
        return OrganizationsApi.update(params);
    }
)


interface OrganizationsSliceState {
    organizations: Organization[],

    organizationsLS: LS;
    syncLS: LS;
    updateLS: LS;
}


const initialState: OrganizationsSliceState = {
    organizations: [],

    organizationsLS: LS.idle,
    syncLS: LS.idle,
    updateLS: LS.idle,
};


const organizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    reducers: {
        clearSync(state) {
            state.syncLS = LS.idle;
        },
        clearOrganizationUpdate(state) {
            state.updateLS = LS.idle;
        }
    },
    extraReducers: (builder) => {
        // LOAD
        builder.addCase(loadOrganizations.pending, (state, action) => {
            state.organizationsLS = LS.pending;
        })
        builder.addCase(loadOrganizations.fulfilled, (state, action) => {
            state.organizationsLS = LS.success;
            state.organizations = action.payload;
        })
        builder.addCase(loadOrganizations.rejected, (state, action) => {
            state.organizationsLS = LS.error;
        })

        // SYNC
        builder.addCase(syncOrganizations.pending, (state, action) => {
            state.syncLS = LS.pending;
        })
        builder.addCase(syncOrganizations.fulfilled, (state, action) => {
            state.syncLS = LS.success;
        })
        builder.addCase(syncOrganizations.rejected, (state, action) => {
            state.syncLS = LS.error;
        })

        // UPDATE
        builder.addCase(updateOrganization.pending, (state, action) => {
            state.updateLS = LS.pending;
        })
        builder.addCase(updateOrganization.fulfilled, (state, action) => {
            state.updateLS = LS.success;
            const updated = action.payload;
            state.organizations = state.organizations.map(org => org._id === updated._id ? updated : org);
        })
        builder.addCase(updateOrganization.rejected, (state, action) => {
            state.updateLS = LS.error;
        })
    }
});


export const { clearSync, clearOrganizationUpdate } = organizationsSlice.actions;
export default organizationsSlice.reducer;