import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncAlert } from 'types/AsyncAlert';



interface AlertSliceState {
    current?: AsyncAlert;
}

const initialState: AlertSliceState = {
    current: undefined,
};


const AlertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        setAlert(state, action: PayloadAction<AsyncAlert | undefined>) {
            state.current = action.payload;
        }
    },
    extraReducers: (builder) => {}
});


export const { setAlert } = AlertSlice.actions;
export default AlertSlice.reducer;